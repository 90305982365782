import router from '@/router'
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPreOrders() {
      return new Promise((resolve, reject) => {
        axios
          .get('/categories?options={"parent_id":"2"}')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPreOrder() {
      const { id } = router.currentRoute.params

      return new Promise((resolve, reject) => {
        axios
          .get(`/categories/view/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addPreOrder(ctx, mealData) {
      const bodyFormData = new FormData()
      bodyFormData.append('name_en', mealData.name_en)
      bodyFormData.append('name_ar', mealData.name_ar)
      bodyFormData.append('image', mealData.image)
      bodyFormData.append('add_ones', mealData.add_ones === true ? 1 : 0)
      bodyFormData.append('used_in_all_meals', mealData.used_in_all_meals === true ? 1 : 0)
      bodyFormData.append('parent_id', 2)

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/categories/create',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    editPreOrder(ctx, updatedPreOrderData) {
      const bodyFormData = new FormData()
      bodyFormData.append('name_en', updatedPreOrderData.name_en)
      bodyFormData.append('name_ar', updatedPreOrderData.name_ar)
      updatedPreOrderData.image && bodyFormData.append('image', updatedPreOrderData.image)
      bodyFormData.append('add_ones', 0)
      bodyFormData.append('used_in_all_meals', 0)
      bodyFormData.append('parent_id', 2)
      bodyFormData.append('id', router.currentRoute.params.id)

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/categories/update   ',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    deletePreOrder(ctx, _id) {
      const data = { id: _id }

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/categories/delete',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
