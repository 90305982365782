<template>
  <div id="preorder-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <pre-bio-panel
          :pre-data="preData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refresh="udpatePreData"
        ></pre-bio-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline } from '@mdi/js'
import preorderStoreModule from '../preordersStoreModule'
import preBioPanel from './pre-bio-panel/PreBioPanel.vue'

export default {
  components: {
    preBioPanel,
  },
  methods: {
    udpatePreData(value) {
      this.preData = value
    },
  },
  setup() {
    const PREORDERS_APP_STORE_MODULE_NAME = 'app-preorders'

    // Register module
    if (!store.hasModule(PREORDERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(PREORDERS_APP_STORE_MODULE_NAME, preorderStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PREORDERS_APP_STORE_MODULE_NAME)) store.unregisterModule(PREORDERS_APP_STORE_MODULE_NAME)
    })

    const preData = ref({})
    const preorderTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    store
      .dispatch('app-preorders/fetchPreOrder', { id: router.currentRoute.params.id })
      .then(response => {
        preData.value = response.data.data
        selectedPlan.value = response.data.currentPlan
      })
      .catch(error => {
        if (error.response.status === 404) {
          preData.value = {}
        }
      })

    const tabs = [{ icon: mdiAccountOutline, title: 'Overview' }]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      tabs,
      preorderTab,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      preData,
      resolveCurrentPlanValue,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
