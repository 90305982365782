<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="pre-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit Pre-Order Information
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Updating Pre-Order details will receive a privacy audit.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form
          ref="form"
          v-model="valid"
          class="multi-col-validation"
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="preDataLocal.name_en"
                :rules="[validators.required]"
                outlined
                dense
                label="Name (En)"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="preDataLocal.name_ar"
                outlined
                dense
                :rules="[validators.required]"
                label="Name (Ar)"
              ></v-text-field>

              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Choose an image"
                outlined
                dense
                label="Upload Image"
                hide-details="auto"
                class="mb-6 mt-6"
                @change="handleChange($event)"
              ></v-file-input>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                submit
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-bio-dialog-open',false)"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { emailValidator, integerValidator, required } from '@core/utils/validation'
import { ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    preData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isBillingAddress = ref(true)

    const preDataLocal = ref({})

    const parsedPreData = JSON.parse(JSON.stringify(props.preData))

    preDataLocal.value = parsedPreData

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const resetPreData = () => {
      preDataLocal.value = JSON.parse(JSON.stringify(preDataLocal))
      resetForm()
    }

    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]
    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']

    // on form submit
    const onSubmit = async () => {
      if (preDataLocal.value.image === props.preData.image) delete preDataLocal.value.image
      if (valid.value) {
        try {
          const data = await store.dispatch('app-preorders/editPreOrder', preDataLocal.value)
          const responseData = data.data.data.category
          emit('refresh', responseData)
          emit('update:is-bio-dialog-open', false)
        } catch (error) {
          Object.entries(error.data.message).forEach(err => {
            Swal.fire({
              icon: 'error',
              title: `${err[0]}`,
              text: `${err[1][0]}`,
              footer:
                'Desired dimensions: min-width: 126px; max-width: 252px; min-height: 95px; max-height: 189px; max-file-size: 512kb',
            })
          })
        }
      } else {
        validate()
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        preDataLocal.value = parsedPreData
      },
    )

    const handleChange = e => {
      preDataLocal.value.image = e
    }

    return {
      isBillingAddress,
      statusOptions,
      languageOptions,
      preDataLocal,
      resetPreData,
      form,
      valid,
      validate,
      onSubmit,
      handleChange,

      // validation
      validators: { required, emailValidator, integerValidator },
    }
  },
}
</script>
