<template>
  <v-row class="preorder-bio-panel">
    <!-- size profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="preData.avatar ? '' : 'primary'"
            :class="preData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              :src="preData.image"
            ></v-img>
          </v-avatar>
          <span class="mb-2">{{ preData.name_en }}</span>
        </v-card-title>


        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Name (En):</span>
              <span class="text--secondary">{{ preData.name_en }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Name (Ar):</span>
              <span class="text--secondary">{{ preData.name_ar }}</span>
            </v-list-item>
            <v-card-actions class="justify-center pt-5">
              <v-btn
                color="primary"
                class="me-3"
                @click="isBioDialogOpen = !isBioDialogOpen"
              >
                Edit
              </v-btn>
              <v-btn
                color="error"
                outlined
                @click="handleDelete(preData.id)"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-list>
        </v-card-text>
      </v-card>

      <!-- edit profile dialog data -->
      <pre-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :pre-data="preData"
        @refresh="updatePreData"
      ></pre-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle, mdiStarOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'

import preBioEdit from './PreBioEdit.vue'

export default {
  components: {
    preBioEdit,
  },
  props: {
    preData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updatePreData(newValue) {
      this.$emit('refresh', newValue)
    },
  },
  setup() {
    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    const handleDelete = id => {
      Swal.fire({
        title: 'Are you sure you want to delete this item?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-preorders/deletePreOrder', id)
            .then(() => {
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
              router.push({ name: 'apps-preorders-list' })
            })
            .catch(response => {
              console.error(response)
            })
        }
      })
    }

    return {
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,
      handleDelete,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiStarOutline,
      },
    }
  },
}
</script>
